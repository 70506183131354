import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {Deriv} from "../deriv";

const initialState = {
    accounts: [],
    status: 'idle',
    error: null
}

export const fetchAccounts = createAsyncThunk('accounts/fetchAccounts', async (tokens: [],thunkAPI) => {
    try {
        const api = Deriv();
        const data = [];
        for (const value of tokens) {
            let row = {};
            const apiAccount = await api.account(value.token);
            if (apiAccount.loginid) {
                row.loginid = apiAccount.loginid;
                row.token = value.token;
                row.currency = apiAccount.balance.amount.currency;
                row.balance = apiAccount.balance.amount.value;
                if (value.account.substring(0, 2) !== "VR" && (value.currency === "USD" || value.currency === "EUR" || value.currency === "GBP" || value.currency === "AUD")) {
                    row.selected = 1;
                } else {
                    row.selected = 0;
                }
            }
            data.push(row);
        }
        api.basic.connection?.close();
        return data;
    } catch (err) {
        return err.message;
    }
});


const accountsSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchAccounts.pending, (state, action) => {
            state.status = 'loading'
        }).addCase(fetchAccounts.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.accounts = action.payload;
        }).addCase(fetchAccounts.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
    }
})

export const selectAccounts = (state) => state.accounts.accounts;
export const getAccountsStatus = (state) => state.accounts.status;
export const getAccountsError = (state) => state.accounts.error;


export default accountsSlice.reducer