import * as React from "react";
import {Route, Routes} from "react-router-dom";
import Layout from "./components/private/Layout";
import {AccessTime, CandlestickChart, MiscellaneousServices} from "@mui/icons-material";
//import {useDispatch, useSelector} from "react-redux";
//import {fetchAccounts, getAccountsError, getAccountsStatus, selectAccounts} from "./features/account/accountSlice";
//import {fetchUser, getUserError, getUserStatus, selectUser} from "./features/user/userSlice";
import {AccountsTokens} from "./hooks/AccountsTokens";
import {DefaultAccountToken} from "./hooks/DefaultAccountToken";
import {Box, Divider, List, ListItem, ListItemIcon, ListItemText, Skeleton} from "@mui/material";
import {timeFrames} from "./constants/timeFrame";
//import {useGetCandleQuery, useGetSymbolQuery} from "./features/api/apiSlice";
import {useState} from "react";
//import {Deriv} from "./features/deriv";
import DerivAPI from "@deriv/deriv-api/dist/DerivAPI";
import {configDeriv} from "./constants/deriv";

const api = new DerivAPI({app_id: configDeriv.appid, endpoint: configDeriv.endpoint, lang: configDeriv.lang});

function App() {
    const tokens = AccountsTokens();
    const token = DefaultAccountToken();

    //const dispatch = useDispatch();
    const timeFrame = timeFrames;
    const [candles, setCandles] = useState({epoch:0});
    const [candlesOld, setCandlesOld] = useState({epoch:0});
    const [candlesIsSuccess, setCandlesIsSuccess] = useState(false);


    /*const [initialState, setInitialState] = React.useState(false);
    const [candlesList, setCandlesList] = React.useState([]);
    const [unlockCandles, setUnlockCandles] = React.useState(true);
    const [connTicks, setConnTicks] = React.useState([]);
    const [stateConnTicks, setStateConnTicks] = React.useState(false);
    const [unlockConnTicks, setUnlockConnTicks] = React.useState(true)

    const getAccounts = React.useMemo(() => {
        return dispatch(fetchAccounts(tokens));
    }, []);

    const getUser = React.useMemo(() => {
        return dispatch(fetchUser(token));
    }, []);

    const getSymbols = React.useMemo(() => {
        return dispatch(fetchSymbols());
    }, []);

    const accounts = useSelector(selectAccounts);
    const accountsStatus = useSelector(getAccountsStatus);
    const accountsError = useSelector(getAccountsError);

    const user = useSelector(selectUser);
    const userStatus = useSelector(getUserStatus);
    const userError = useSelector(getUserError);

    const symbols = useSelector(selectSymbols);
    const symbolsStatus = useSelector(getSymbolsStatus);
    const symbolsError = useSelector(getSymbolsError);

    React.useEffect(() => {
        if (tokens) {
            if (accountsStatus === 'idle') {
                getAccounts();
            }
            if (userStatus === 'idle') {
                getUser();
            }
            if (symbolsStatus === 'idle') {
                getSymbols();
            }
        }
    }, []);

    const ticks = [];
    const stream = [];
    const api = [];

    const connectTicks = React.useCallback(async (symbols) => {
        for (const val of symbols) {
            api[val.symbol] = Deriv();
        }
        return api;
    }, []);

    const getCandles = React.useCallback(async (symbols, connTicks) => {
        if (symbols) {
            for (const val of symbols) {
                ticks[val.symbol] = await connTicks[val.symbol].ticks(val.symbol);
                candles[val.symbol] = [];
                for (const time of timeFrame) {
                    if (Object.values(time) > 0) {
                        candles[val.symbol][Object.values(time)] = [];
                        candles[val.symbol][Object.values(time)].lastId = 0;
                    }
                }
                stream[val.symbol] = ticks[val.symbol]?.onUpdate()?.subscribe((data) => {
                    const tick = data.raw;
                    for (const time of timeFrame) {
                        if (Object.values(time) > 0) {
                            if (((tick.epoch % Object.values(time)) === 0) || (candles[val.symbol][Object.values(time)].lastId === 0)) {
                                candles[val.symbol][Object.values(time)].lastId = tick.epoch;
                                candles[val.symbol][Object.values(time)][candles[val.symbol][Object.values(time)].lastId] = {
                                    open: tick.quote,
                                    close: tick.quote,
                                    openTime: tick.epoch,
                                    ticks: [],
                                    min: tick.quote,
                                    max: tick.quote,
                                    countdown: (tick.symbol.substring(0, 1) === "R") ? (Object.values(time) - 2) - (tick.epoch % Object.values(time)) : (Object.values(time) - 1) - (tick.epoch % Object.values(time))
                                }
                            }

                            if ((tick.epoch % Object.values(time)) === 0) {
                                candles[val.symbol][Object.values(time)][candles[val.symbol][Object.values(time)].lastId].open = tick.quote;
                                candles[val.symbol][Object.values(time)][candles[val.symbol][Object.values(time)].lastId].openTime = tick.epoch;
                            }
                            candles[val.symbol][Object.values(time)][candles[val.symbol][Object.values(time)].lastId].countdown = (tick.symbol.substring(0, 1) === "R") ? (Object.values(time) - 2) - (tick.epoch % Object.values(time)) : (Object.values(time) - 1) - (tick.epoch % Object.values(time));
                            candles[val.symbol][Object.values(time)][candles[val.symbol][Object.values(time)].lastId].ticks.push(tick.quote);
                            candles[val.symbol][Object.values(time)][candles[val.symbol][Object.values(time)].lastId].min = Math.min(...candles[val.symbol][Object.values(time)][candles[val.symbol][Object.values(time)].lastId].ticks);
                            candles[val.symbol][Object.values(time)][candles[val.symbol][Object.values(time)].lastId].max = Math.max(...candles[val.symbol][Object.values(time)][candles[val.symbol][Object.values(time)].lastId].ticks);

                            if (tick.symbol.substring(0, 1) === "R") {
                                if (((tick.epoch + 2) % Object.values(time)) === 0) {
                                    candles[val.symbol][Object.values(time)][candles[val.symbol][Object.values(time)].lastId].close = tick.quote;
                                }
                            } else {
                                if (((tick.epoch + 1) % Object.values(time)) === 0) {
                                    candles[val.symbol][Object.values(time)][candles[val.symbol][Object.values(time)].lastId].close = tick.quote;
                                }
                            }
                        }
                    }

                    if (candles) {
                        setCandlesList(candles);
                        //console.log(candle);
                    }
                }, (err) => {
                    console.log(err)
                });
            }
        }
    }, []);

    React.useEffect(() => {
        if ((symbolsStatus === 'succeeded') && unlockConnTicks === true) {
            (async () => {
                try {
                    const conn = await connectTicks(symbols);
                    setConnTicks(conn);
                    setUnlockConnTicks(false)
                    setStateConnTicks(true);
                } catch (e) {
                    console.log(e);
                }
            })();
            setUnlockConnTicks(false)
        }
    }, [symbolsStatus]);

    React.useEffect(() => {
        if ((symbolsStatus === 'succeeded') && stateConnTicks === true) {
            //getCandles(symbols, connTicks);
            setUnlockCandles(false);
        }
    }, [stateConnTicks, candlesList]);

    React.useEffect(() => {
        if (userStatus === 'succeeded' && accountsStatus === 'succeeded') {
            setInitialState(true);
        }
    }, [userStatus, accountsStatus]);

    React.useEffect(() => {

    }, [candlesList]);*/


    //const symbolApi = useGetSymbolQuery();

    const symbols = [
        {symbol: "R_10"},
        {symbol: "1HZ10V"},
        {symbol: "R_25"},
        {symbol: "1HZ25V"},
        {symbol: "R_50"},
        {symbol: "1HZ50V"},
        {symbol: "R_75"},
        {symbol: "1HZ75V"},
        {symbol: "R_100"},
        {symbol: "1HZ100V"},
        {symbol: "RDBULL"},
        {symbol: "RDBEAR"}
    ];
    const granularities = timeFrames;
    //const candleApi = useGetCandleQuery({symbols: symbols, granularities: granularities});


    React.useEffect(() => {
        (async function () {
            const tick = await api.ticks("R_10");
            await tick?.onUpdate((data)=>{
                setCandlesIsSuccess(true);
                setCandles(data.raw);
            },(err)=>{
                console.log(err);
                //api.basic.connection?.close();
            })?.subscribe();
            /*if(!candlesIsSuccess){
                await tickStream();
            }
            api.basic.connection?.addEventListener("message", (res) => {
                const data = JSON.parse(res.data);
                const tick = "tick" in data ? data.tick : {};

                if ("error" in data) {
                    console.error(data.error);
                }

                if ("symbol" in tick) {
                    setCandlesIsSuccess(true);
                    setCandles(tick);
                }
            });*/
        })()
        /*if(candleApi){
            if (candleApi.isLoading) {
                console.log("Loading...");
                setCandlesIsSuccess(false);
            }
            if (candleApi.isSuccess) {
                if (candleApi.data.length > 0) {
                    setCandles(candleApi.data[0]);
                    setCandlesIsSuccess(true);
                }
                candleApi.refetch();
            }
            if (candleApi.isError) {
                console.log(candleApi.error);
                setCandlesIsSuccess(false)
            }
        }*/
        //console.log(localStorage.getItem("R_10"));

        //return ()=>{window.removeEventListener('storage',checkCandles)}

        //setCandles(JSON.parse(localStorage.getItem("R_10")));
        //console.log(candles.epoch != candlesOld.epoch);

    },[]);

    /*React.useEffect(() => {
        if (symbolApi.isLoading) {
            console.log("Loading...");
        }
        if (symbolApi.isSuccess) {
            if (symbolApi.data.length > 0) {
                //console.log(symbolApi.data[0]);
                //console.log(candleApi);
            }
        }
        if (symbolApi.isError) {
            console.log(symbolApi.error);
        }
    }, [symbolApi.data]);*/


    /*let contentAccounts;
    if (accountsStatus === 'loading') {
        contentAccounts = <p>"Loading Accounts..."</p>
    } else if (accountsStatus === 'succeeded') {
        //console.log(accounts);
    } else if (accountsStatus === 'failed') {
        contentAccounts = <p>{accountsError}</p>
    }

    let contentUser;
    if (userStatus === 'loading') {
        contentUser = <p>"Loading User..."</p>
    } else if (userStatus === 'succeeded') {
        //console.log(user);
    } else if (userStatus === 'failed') {
        contentUser = <p>{userError}</p>
    }

    let contentSymbols;
    if (symbolsStatus === 'loading') {
        contentSymbols = <p>"Loading Symbols..."</p>
    } else if (symbolsStatus === 'succeeded') {
        //console.log(symbols);
    } else if (symbolsStatus === 'failed') {
        contentSymbols = <p>{symbolsError}</p>
    }

    let contentConnection;
    if (!stateConnTicks) {
        contentConnection = <p>"Loading Connections..."</p>
    }*/

    return (
        <Routes>
            <Route path={"/"} element={<Layout/>}>
                <Route index element={
                    <Box>
                        {candlesIsSuccess ?
                            <Box>
                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                fontSize: 20,
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }}>
                                            {candles.symbol}
                                        </ListItemText>
                                    </ListItem>
                                    <Divider/>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AccessTime/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            {candles.epoch}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CandlestickChart/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            {candles.quote}
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Box>
                            :
                            <Skeleton/>
                        }
                    </Box>

                }/>
                <Route path={"*"} element={<MiscellaneousServices/>}/>
            </Route>
        </Routes>
    );
}

export default App;
