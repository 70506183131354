import {createApi, fakeBaseQuery} from "@reduxjs/toolkit/query/react";
import {Deriv} from "../deriv";

export const apiSlice = createApi({
    baseQuery: fakeBaseQuery(),
    endpoints: (build) => ({
        getCandle: build.query({
            queryFn: (arg) => {
                return {data: []};
            },
            async onCacheEntryAdded(
                arg,
                {updateCachedData, cacheDataLoaded, cacheEntryRemoved}
            ) {
                const api = Deriv();
                try {
                    await cacheDataLoaded;

                    const tick = await api.ticks("R_10");
                    await tick?.onUpdate()?.subscribe((data) => {
                        (async function(){
                            await cacheEntryRemoved;
                        })()
                        updateCachedData((draft) => {
                            draft.push(data.raw);
                        });
                    });
                } catch (e) {
                    console.log(e);
                }
                await cacheEntryRemoved;
            },
        }),
        getSymbol: build.query({
            queryFn: () => {
                return {data: []};
            },
            async onCacheEntryAdded(
                arg,
                {updateCachedData, cacheDataLoaded, cacheEntryRemoved}
            ) {
                const api = Deriv();
                try {

                    const basic = await api.basic;
                    await cacheDataLoaded;
                    const dataTmp = [];
                    const symbolsList = await basic.activeSymbols({
                        "active_symbols": "full",
                        "product_type": "basic"
                    })

                    for await (const val of symbolsList?.active_symbols) {
                        if (val?.market) {
                            if (val.market === 'synthetic_index') {
                                let row = {};
                                row.display_name = val.display_name;
                                row.symbol = val.symbol;
                                row.symbol_type = val.symbol_type;
                                row.market = val.market;
                                row.market_display_name = val.market_display_name;
                                row.submarket = val.submarket;
                                row.submarket_display_name = val.submarket_display_name;
                                dataTmp.push(row);
                            }
                        }
                    }
                    updateCachedData((draft) => {
                        draft.push(dataTmp);
                    })

                    api.basic.connection?.close();
                } catch (e) {
                    console.log(e);
                }
                await cacheEntryRemoved
                api?.basic?.connection?.close();
            },
        })
    })
});

export const {
    useGetCandleQuery,
    useGetSymbolQuery
} = apiSlice;