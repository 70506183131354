import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Deriv} from "../deriv";

const initialState = {
    user: {},
    status: 'idle',
    error: null
}



export const fetchUser = createAsyncThunk('user/fetchUser', async (token:{},thunkAPI) => {
    try {
        const api = Deriv();
        const apiUser = await api.account(token);
        let data = {};
        data.id = apiUser.user_id;
        data.email = apiUser.email;
        data.fullname = apiUser.fullname;
        data.country = apiUser.country;
        data.lang = apiUser.balance.amount.lang;
        api.basic.connection?.close();
        return data;
    } catch (err) {
        return err.message;
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers(builder){
        builder.addCase(fetchUser.pending,(state, action)=>{
            state.status = 'loading'
        }).addCase(fetchUser.fulfilled,(state, action)=>{
            state.status = 'succeeded'
            state.user = action.payload;
        }).addCase(fetchUser.rejected,(state, action)=>{
            state.status = 'failed'
            state.error = action.error.message
        })
    }
})

export const selectUser = (state) => state.user.user;
export const getUserStatus = (state) => state.user.status;
export const getUserError = (state) => state.user.error;

//export const {signin, signout} = userSlice.actions;

export default userSlice.reducer;