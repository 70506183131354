export const ParamsToObject = (entries) => {
    const result = []
    for (const [key, value] of entries) {
        if (!!key && !!value) {
            if (key !== "state") {
                if (result[key.substring(key.length - 1) - 1] == null) {
                    result[key.substring(key.length - 1) - 1] = {};
                }
                if (key.substring(0, 4) === "acct") {
                        result[key.substring(key.length - 1) - 1].account = value;
                }
                if (key.substring(0, 3) === "cur") {
                        result[key.substring(key.length - 1) - 1].currency = value;
                }
                if (key.substring(0, 5) === "token") {
                        result[key.substring(key.length - 1) - 1].token = value;
                }
            }
        }
    }
    return result;
}