import * as React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./app/store";
import {ApiProvider} from "@reduxjs/toolkit/dist/query/react";
import {candleSlice} from "./features/candle/candleSlice";
import {apiSlice} from "./features/api/apiSlice";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ApiProvider api={apiSlice}>
            <Provider store={store}>
                <BrowserRouter>
                    <Routes>
                        <Route path={"/*"} element={<App/>}/>
                    </Routes>
                </BrowserRouter>
            </Provider>
        </ApiProvider>
    </React.StrictMode>
);

