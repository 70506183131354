import {createApi} from "@reduxjs/toolkit/query/react";
import {Deriv} from "../deriv";

export const candleSlice = createApi({
    reducerPath: 'candleApi',
    async baseQuery(){
        return {data:[]};
    },
    endpoints: (build) => ({
        getCandle: build.query({
            queryFn: (symbols)=> {
                return {data:[]};
            },
            async onCacheEntryAdded(
                arg,
                {updateCachedData, cacheDataLoaded, cacheEntryRemoved}
            ){
                try{
                    const api = Deriv();
                    const ticks = await api.ticks(arg);
                    await cacheDataLoaded;
                    await ticks.onUpdate().subscribe((data)=>{
                        updateCachedData((draft) => {
                            draft.push(data.raw);
                        })
                    });

                }catch (e) {
                    console.log(e);
                }
                await cacheEntryRemoved
            },
        })
    })
});

export const {useGetCandleQuery} = candleSlice;