import {ParamsToObject} from "./ParamsToObject";

export const DefaultAccountToken = ()=>{
    const {search} = window.location;
    if(search) {
        const query = new URLSearchParams(search);
        const accounts = ParamsToObject(query.entries());
        let account = {};
        accounts.map(val => {
            if (val.account.substring(0, 2) !== "VR" && (val.currency === "USD" || val.currency === "EUR" || val.currency === "GBP" || val.currency === "AUD")) {
                account = val;
            }
        });
        return account;
    }
    return undefined;
}